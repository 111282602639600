import styled from '@emotion/styled';
import { Theme, useMediaQuery, useTheme } from '@mui/material';

import { Icon } from './Icon';

const LogoStyled = styled(Icon)`
	display: inline-flex;
	align-items: center;

	width: 82px;
	height: 20px;

	svg {
		width: 82px;
		height: 20px;
	}

	${({ theme }) => theme.breakpoints.up('sm')} {
		width: 130px;
		height: 30px;
		svg {
			width: 130px;
			height: 30px;
		}
	}
`;

export const Logo = () => {
	const theme = useTheme() as Theme;
	const isDesktopMatch = useMediaQuery(theme.breakpoints.up('sm'));

	return <LogoStyled type={isDesktopMatch ? 'logo-white' : 'logo-white-mobile'} />;
};
