import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';

const ContentContainer = styled('div')`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;

export const MainContainer = styled('main')<{ allowOverflow?: boolean }>`
	display: block;
	overflow: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'hidden')};
`;

interface LayoutProps {
	children: ReactNode;
	allowOverflow?: boolean;
}

export const Layout: FC<LayoutProps> = ({ children, allowOverflow }) => (
	<ContentContainer>
		<MainContainer allowOverflow={allowOverflow}>{children}</MainContainer>
	</ContentContainer>
);
