import { FC } from 'react';

import styled from '@emotion/styled';
import { Theme, useMediaQuery, useTheme } from '@mui/material';

import { Logo } from '@fllite-fe/shared/src/components/Logo';

import { DesktopView } from './DesktopView';
import { MobileView } from './MobileView';
import { NavigationLink } from './NavigationLink';

export const NAVBAR_HEIGHT = 80;

interface ContainerProps {
	hasBackground?: boolean;
}
const Container = styled('div')<ContainerProps>`
	padding: 16px 24px;
	background: ${({ theme }) => theme.backgrounds.secondaryGradient};
	${({ theme }) => theme.breakpoints.up('sm')} {
		${({ hasBackground }) => !hasBackground && `background: transparent;`}
		padding: 19px 20px;
		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		z-index: 16;
	}
`;

const Content = styled('div')`
	width: 100%;
	max-width: ${({ theme }) => theme.dimensions.pageWidthLarge};
	margin: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

interface NavigationProps {
	hasBackground?: boolean;
}
export const Navigation: FC<NavigationProps> = ({ hasBackground = false }) => {
	const theme = useTheme() as Theme;
	const isDesktopMatch = useMediaQuery(theme.breakpoints.up('sm'));

	return (
		<Container hasBackground={hasBackground}>
			<Content>
				<NavigationLink href="/" dataTestid="logo" disabledText>
					<Logo />
				</NavigationLink>
				{isDesktopMatch ? <DesktopView /> : <MobileView />}
			</Content>
		</Container>
	);
};
