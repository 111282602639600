import { useState } from 'react';

import { Menu, MenuItem } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { getContextForApp, useAuthentication } from '@fllite-fe/shared';
import { Avatar } from '@fllite-fe/shared/src/components/Avatar';

import { AvatarWrapper, TextWithPointer, UserMenu, UserName } from './UserBox.style';

export type UserBoxProps = {
	isHomepage?: boolean;
};

export const UserBox = ({ isHomepage = false }: UserBoxProps) => {
	const { user, logout } = useAuthentication();
	const { push } = useRouter();
	const { clientSectionUrl } = getContextForApp();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: any) => setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const handleLogout = () => {
		logout();
	};

	const showContactUsForm = () => {
		push('/contact-us');
	};

	return (
		<>
			<UserMenu
				as="button"
				onClick={handleClick}
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				style={{ border: 'none' }}
				data-testid="navbarMyProfile"
			>
				<UserName medium>{user?.firstName}</UserName>
				<AvatarWrapper>
					<Avatar />
				</AvatarWrapper>
			</UserMenu>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{user?.confirmed && [
					<Link
						href={isHomepage ? `${clientSectionUrl}/my-profile` : '/my-profile'}
						passHref
						key="my_profile"
						legacyBehavior
					>
						<MenuItem onClick={handleClose}>
							<TextWithPointer gray>My profile</TextWithPointer>
						</MenuItem>
					</Link>,
					<MenuItem onClick={showContactUsForm} key="contact_us">
						<TextWithPointer gray>Contact us</TextWithPointer>
					</MenuItem>,
				]}

				<MenuItem onClick={handleLogout}>
					<TextWithPointer gray>Logout</TextWithPointer>
				</MenuItem>
			</Menu>
		</>
	);
};
