import styled from '@emotion/styled';

import { Text } from '../Text';

export const AvatarWrapper = styled('div')`
	margin-left: 10px;

	img {
		object-fit: cover;
	}
`;

export const TextWithPointer = styled(Text)`
	cursor: pointer;
`;

export const UserMenu = styled('div')`
	background: ${({ theme }) => theme.colors.primaryDarkest};
	border-radius: 51px;
	display: flex;
	align-items: center;
	padding: 4px 4px 4px 20px;
	margin-left: 30px;
	cursor: pointer;

	${({ theme }) => theme.breakpoints.down('md')} {
		margin-left: 0;
		${Text} {
			color: #fff;
		}
	}
`;

export const UserName = styled(Text)`
	font-size: 14px;
	${({ theme }) => theme.breakpoints.up('sm')} {
		font-size: 16px;
	}
`;
