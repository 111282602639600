import { FC } from 'react';
import * as React from 'react';

import styled from '@emotion/styled';
import Link from 'next/link';
import styledMap from 'styled-map';

import { Text } from '@fllite-fe/shared/src/components/Text';

const anchorBackground = styledMap`
  default: transparent;
  featured: ${({ theme }) => theme.colors.primary};
`;

const anchorBackgroundHover = styledMap`
  default: transparent;
  featured: ${({ theme }) => theme.colors.primaryDark};
`;

const anchorBorderRadius = styledMap`
  default: initial;
  featured: ${({ theme }) => theme.radius.buttonRadius};
`;

const anchorPadding = styledMap`
  default: 0;
  featured: 5px 20px 2px;
`;

interface NavigationLinkProps {
	featured?: boolean;
	href: string;
	children: React.ReactNode;
	hideOnDesktop?: boolean;
	dataTestid?: string;
	disabledText?: boolean;
}

export const Anchor = styled('a')<
	Pick<NavigationLinkProps, 'featured' | 'hideOnDesktop' | 'disabledText'>
>`
	margin: 0 8px;
	padding: ${anchorPadding};
	cursor: pointer;
	background-color: ${anchorBackground};
	border-radius: ${anchorBorderRadius};
	transition: background-color ${({ theme }) => theme.transition.baseTransition};
	${({ disabledText }) => disabledText && 'font-size: 0;'}

	:hover {
		background-color: ${anchorBackgroundHover};
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		display: ${({ hideOnDesktop }) => hideOnDesktop && 'none'};
		visibility: ${({ hideOnDesktop }) => hideOnDesktop && 'hidden'};
	}
`;

export const NavigationLink: FC<NavigationLinkProps> = ({
	featured,
	href,
	children,
	hideOnDesktop,
	dataTestid,
	disabledText,
}) => (
	<Link href={href} passHref legacyBehavior>
		<Anchor
			featured={featured}
			hideOnDesktop={hideOnDesktop}
			data-testid={dataTestid}
			disabledText={disabledText}
		>
			<Text disabledText={disabledText}>{children}</Text>
		</Anchor>
	</Link>
);
