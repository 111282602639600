import styled from '@emotion/styled';
import Link from 'next/link';

import { getContextForApp, useAuthentication } from '@fllite-fe/shared';
import { StyledButton } from '@fllite-fe/shared/src/components/Button';
import { Icon } from '@fllite-fe/shared/src/components/Icon';
import { UserBox } from '@fllite-fe/shared/src/components/UserBox';

import { Anchor } from './NavigationLink';

const NavigationLinkList = styled('div')`
	display: flex;

	${Anchor} {
		display: flex;
		margin-left: 30px;
	}
`;

const DesktopNavigation = styled('div')`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

interface NavigationProps {}
const NavigationWrap = styled('div')<NavigationProps>`
	height: 100%;
`;

const IconPerson = styled(Icon)`
	margin-right: 10px;
	display: inline-flex;
	align-items: center;
	color: white;
	padding-bottom: 2px;
`;

const UpdatedButton = styled(StyledButton)`
	padding: 2px 20px;
	min-height: 32px;
	min-width: 93px;
	font-size: 16px;
	margin-left: 30px;
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;

	&:hover ${IconPerson} {
		color: ${({ theme }) => theme.colors.primary};
	}
`;

const RegisterButton = styled(UpdatedButton)`
	background: linear-gradient(92.58deg, #2077e0 0%, #004ba6 100%);
`;

export const DesktopView = () => {
	const { clientSectionUrl } = getContextForApp();
	const { user } = useAuthentication();
	const isAuthenticated = !!user;

	const unauthenticatedNavigationContent = (
		<DesktopNavigation>
			<Link href={!!user ? clientSectionUrl : `${clientSectionUrl}/login`} passHref legacyBehavior>
				<UpdatedButton outline data-testid="navigation-link-sign-in" as="a" type={null}>
					<IconPerson type="person" />
					Log in
				</UpdatedButton>
			</Link>
			<Link
				href={!!user ? clientSectionUrl : `${clientSectionUrl}/register`}
				passHref
				legacyBehavior
			>
				<RegisterButton data-testid="navigation-link-register" as="a" type={null}>
					Create account
				</RegisterButton>
			</Link>
		</DesktopNavigation>
	);

	const authenticatedNavigationContent = (
		<DesktopNavigation>
			<Link href={clientSectionUrl} passHref legacyBehavior>
				<UpdatedButton outline as="a" type={null}>
					Go to Dashboard
				</UpdatedButton>
			</Link>
			<UserBox isHomepage />
		</DesktopNavigation>
	);

	return (
		<NavigationWrap>
			<NavigationLinkList>
				{isAuthenticated ? authenticatedNavigationContent : unauthenticatedNavigationContent}
			</NavigationLinkList>
		</NavigationWrap>
	);
};
