import { FC } from 'react';

import styled from '@emotion/styled';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import WarningIcon from '@mui/icons-material/Warning';
import { CircularProgress } from '@mui/material';

import { useAuthentication } from '../hooks/useAuthentication';
import { Icon } from './Icon';

type UserType = {
	firstName: string;
	lastName: string;
	avatarUrl?: string;
};

interface AvatarProps {
	className?: string;
	user?: UserType;
	isOwner?: boolean;
	disabled?: boolean;
	isLoading?: boolean;
	isApproved?: boolean;
}

const AvatarBody = styled('div')`
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: ${({ theme }) => theme.colors.grayLight};
	color: #000;
	text-transform: uppercase;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	position: relative;
	${({ theme }) => theme.breakpoints.up('sm')} {
		font-size: 16px;
		width: 34px;
		height: 34px;
	}
`;

const StyledIcon = styled(Icon)`
	width: 14px;
	height: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const CrownCircle = styled('div')`
	border-radius: 50%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: -2px;
	right: -2px;
	background: #ffffff;
`;

const InviteeCircle = styled('div')`
	border-radius: 50%;
	position: absolute;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	top: -2px;
	right: -8px;
	background: linear-gradient(92.58deg, #2077e0 0%, #004ba6 100%);
`;

const Image = styled('img')`
	width: 34px;
	height: 34px;
	border-radius: 50%;
`;

export const Avatar: FC<AvatarProps> = ({
	className,
	user,
	isOwner,
	disabled,
	isLoading,
	isApproved,
}) => {
	const { user: currentUser } = useAuthentication();

	const defaultedUser = user ?? currentUser;

	const avatarUrl = defaultedUser?.avatarUrl;

	const initials = `${defaultedUser?.firstName?.charAt(0) || ''}${
		defaultedUser?.lastName?.charAt(0) || ''
	}`;

	return (
		<AvatarBody className={className}>
			{isOwner && (
				<CrownCircle>
					<StyledIcon type="crown-blue" />
				</CrownCircle>
			)}
			{isApproved && (
				<InviteeCircle>
					<AirplaneTicketIcon sx={{ fontSize: '12px', color: 'white' }} />
				</InviteeCircle>
			)}
			{isLoading && <CircularProgress />}
			{disabled && !isLoading && <WarningIcon />}
			{defaultedUser &&
				!disabled &&
				!isLoading &&
				(avatarUrl ? <Image src={avatarUrl} /> : initials)}
		</AvatarBody>
	);
};
